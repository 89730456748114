import {createMuiTheme} from '@material-ui/core'

const theme = createMuiTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#39375b'
        }
    },
    typography: {
        fontFamily: ['Roboto','Staatliches','Patrick Hand'].join(',')
    }
})

export default theme