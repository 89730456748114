/**
 * Save all the urls that our application will use 
 */

export const BASE_URL = (process.env.NODE_ENV === 'development'? 'http://localhost:5000': 'https://cphf-server.herokuapp.com')
export const LOGIN_URL = BASE_URL  + '/login'
export const SIGNUP_URL = BASE_URL + '/signup'
export const ROOM_URL = BASE_URL + '/rooms'

// user route 
export const USER_URL = BASE_URL + '/user'

// admin route 
const ADMIN_URL = BASE_URL + '/admin'
export const ADMIN_ROOM_URL = ADMIN_URL + '/rooms'