// importing actions constants 
import {
    TOGGLE_LOADING
} from '../../constants/actions'



export const toggleLoading = () => {
    return {
        type: TOGGLE_LOADING
    }
}