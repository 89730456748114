import React from 'react'

import {
    Tooltip,
    Button
} from '@material-ui/core'

const Login = props => {

    return (
        <div>
            <Tooltip title="Login">
                <Button
                    color="inherit"
                    onClick={props.handler}
                >
                    Join
                    </Button>
            </Tooltip>
        </div>
    )
}

export default Login