// importing actions types 
import {
    GET_USER_STAT
} from '../../constants/actions'

const initialState = {
    user: null
}

export default (state = initialState, action) => {
    switch (action.type) {

        case GET_USER_STAT:
            return {
                ...state,
                user: action.payload
            }
        default:
            return state
    }
}