import React, { useState } from 'react'
import {
    ADMIN_ROOM_URL
} from '../../constants/urls'

import Axios from 'axios'

import {
    Grid,
    Paper,
    FormControl,
    TextField,
    makeStyles,
    Select,
    MenuItem,
    Button
} from '@material-ui/core'

const userStyle = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2)
    }
}))

const CreateRoom = props => {
    const classes = userStyle()

    const [roomName, setRoomName] = useState('')
    const [date, setDate] = useState('')
    const [time, setTime] = useState('')
    const [type, setType] = useState(1)

    const createRoom = () => {
        /**
         * Make api call to create room. 
         */
        Axios({
            method: 'POST',
            url: ADMIN_ROOM_URL,
            data: {
                title: roomName,
                time: time,
                date: date,
                type: type
            }
        }).then(res => {
            console.log(res.data)
            alert('room created')
        }).catch(err => {
            console.log(err)
        })
    }

    return (
        <div>
            <Paper variant="outlined" className={classes.paper}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                variant="outlined"
                                label="Room Name"
                                type="text"
                                value={roomName}
                                onChange={(event) => { setRoomName(event.target.value) }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextField
                                id="date"
                                type="date"
                                variant="outlined"
                                value={date}
                                onChange={(event) => { setDate(event.target.value) }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextField
                                id="date"
                                type="time"
                                variant="outlined"
                                value={time}
                                onChange={(event) => { setTime(event.target.value) }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <Select
                                variant="outlined"
                                value={type}
                                onChange={(event) => {
                                    setType(event.target.value)
                                }}
                            >
                                <MenuItem value={1}>Solo</MenuItem>
                                <MenuItem value={2}>Duo</MenuItem>
                                <MenuItem value={3}>Squad</MenuItem>
                            </Select>

                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={createRoom}
                            >
                                Create
                            </Button>
                        </FormControl>
                    </Grid>

                </Grid>
            </Paper>
        </div>
    )
}

export default CreateRoom