import React from 'react'
import {connect} from 'react-redux'

import {
    buyPassAsync
} from '../../store/actions/main'


import {
    makeStyles,
    Card,
    Grid,
    CardContent,
    Typography,
    CardActions,
    Button
} from '@material-ui/core'

const useStyle = makeStyles(theme => ({
    root: {
        margin: theme.spacing(2,4)
    },
    card: {
        padding: theme.spacing(2)
    }
}))

const RoomHolder = props => {
    const classes = useStyle()

    const getType = type => {
        switch(type){
            case 1:
                return 'solo'
            case 2:
                return 'duo'
            case 3:
                return 'squad'
            default:
                return 'Not clear'
        }
    }

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card className={classes.card}>
                        <h1>{props.title}</h1>
                        <CardContent>
                            <Typography>
                                Date: {props.date} <br/>
                                Time: {props.time} <br/>
                                Passes Left: {props.passesLeft} <br/>
                                Type: {getType(props.type)}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button 
                            variant="contained" 
                            color="primary"
                            onClick={() => {props.buyPass(props.id)}}
                            >
                                Buy
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>

        </div>
    )
}

const mapStateToProps = state => {
    return {
        
    }
}

const mapDispathToProps = dispatch => {
    return {
        buyPass: (id) => {dispatch(buyPassAsync(id))}
    }
}

export default connect(mapStateToProps, mapDispathToProps)(RoomHolder)