import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom'

import {connect} from 'react-redux'

//importing pages 
import Index from './pages/index'
import Login from './pages/auth/login'
import Signup from './pages/auth/signup'
import Leaderboard from './pages/leaderboard'
import Rooms from './pages/rooms'
import User from './pages/user'
import Admin from './pages/admin/dashboard'

// importing components
import Navbar from './components/navbar/navbar'

import {
  makeStyles,
  Backdrop,
  CircularProgress
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));


function App(props) {
  const classes = useStyles()
  return (
    <div>

      <Backdrop
      className={classes.backdrop}
      open={props.isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Router>
        <Navbar />
        <Switch>
          <Route exact path="/" component={Index} />

          <Route exact path="/login" component={Login} />

          <Route exact path="/signup" component={Signup} />

          <Route exact path="/leaderboard" component={Leaderboard} />

          <Route exact path="/rooms" component={Rooms} />

          <Route exact path="/admin/dashboard" component={Admin} /> 

          <Route exact path="/:username" component={User} /> 

        </Switch>

      </Router>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    isLoading: state.ui.isLoading
  }
}

const mapDispatchToProps = dispatch => {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
