// importing modules 
import Axios from 'axios'

// importing urls
import {
    USER_URL
} from '../../constants/urls'

// importing action types 
import {
    GET_USER_STAT
} from '../../constants/actions'

// sync actions 
const getUserStat = user => {
    return {
        type: GET_USER_STAT,
        payload: user
    }
}



// async actions 
export const getUserStatAsync = username => {
    return dispatch => {
        Axios({
            method: 'GET',
            url: USER_URL + `/${username}`
        }).then(res => {
            dispatch(getUserStat(res.data))
        }).catch(err => {
            console.log(err)
        })
    }
}


