import React from 'react'

import {
    makeStyles,
    Grid,
    FormControl,
    TextField,
    Select,
    MenuItem,
    Paper,
    Typography
} from '@material-ui/core'

const useStyle = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2)
    }
}))

const UpdateStat = props => {

    const classes = useStyle()
    const dummyUser = [
        {username: 'Souvik'},
        {username: 'Souvik'},
        {username: 'Souvik'},
        {username: 'Souvik'},
    ]

    return (
        <div>

            <Paper variant="outlined" className={classes.paper}>

                <Typography align="center">
                    Update User Stat
                </Typography>

                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <Select variant="outlined">
                                {['room 1', 'room 2'].map(el => <MenuItem>{el}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>

                        {
                            dummyUser.map(el => <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <Typography>
                                        {el.username}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth>
                                        <TextField variant="outlined" label="kills" type="number" value={2} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth>
                                        <TextField variant="outlined" label="Deaths" type="number" value={2} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth>
                                        <TextField variant="outlined" label="Matches" type="number" value={2} />
                                    </FormControl>
                                </Grid>
                            </Grid>)
                        }

                    </Grid>

                </Grid>

            </Paper>
            
        </div>
    )
}

export default UpdateStat