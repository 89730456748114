import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'

//importing actions 
import {
    getUserStatAsync as getUser
} from '../store/actions/user'
import { useState } from 'react'

const User = props => {

    const { username } = useParams()

    useState(() => {
        props.getUser(username)
    }, [])

    return (
        <div>
            <h1>User Page</h1>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUser: (username) => dispatch(getUser(username))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(User)