import React from 'react'
import {
    useHistory
} from 'react-router-dom'

import {
    AppBar,
    Toolbar,
    Typography,
    makeStyles,
    Button
} from '@material-ui/core'

import Login from './login'
import UserIcon from './user.icon'

const useStyle = makeStyles(theme => ({
    root: {
        flexGrow: 1
    },
    title: {
        flexGrow: 1
    }
}))

const Navbar = props => {
    const classes = useStyle()
    const history = useHistory()
    const joinRoute = () => { history.push('/login') }
    const roomRoute = () => { history.push('/rooms') }
    const ledearboardRoute = () => {history.push('/leaderboard')}

    return (
        <div className={classes.root}>
            <AppBar position="static" variant="outlined">
                <Toolbar>
                    <Typography variant="h5" className={classes.title} style={{ fontFamily: 'Staatliches' }}>
                        CPHF
                    </Typography>

                    <Button
                        color="inherit"
                        onClick={roomRoute}
                    >
                        Rooms
                    </Button>

                    <Button
                    color="inherit"
                    onClick={ledearboardRoute}
                    >
                        LeadearBoard
                    </Button>

                    {(localStorage.getItem('cphf-token') ? <UserIcon /> : <Login handler={joinRoute} />)}

                </Toolbar>
            </AppBar>
        </div>
    )
}

export default Navbar