/**
 * All the actions that will be used by default
 */

import Axios from 'axios'

import {
    GET_ROOMS,
    BUY_PASS
} from '../../constants/actions'

import {
    ROOM_URL
} from '../../constants/urls'

import {
    toggleLoading
} from './ui'

const getRooms = rooms => {
    return {
        type: GET_ROOMS,
        payload: rooms
    }
}

const buyPass = () => {
    return {
        type: BUY_PASS
    }
}

// async actions 
export const getRoomsAsync = () => {
    return dispatch => {
        Axios({
            method: "GET",
            url: ROOM_URL
        }).then(res => {
            console.log(res.data)
            dispatch(getRooms(res.data))
        }).catch(err => {
            console.log(err)
        })
    }
}

export const buyPassAsync = id => {
    const token = localStorage.getItem('cphf-token')
    return dispatch => {
        dispatch(toggleLoading())
        Axios({
            method: "PATCH",
            url: ROOM_URL,
            headers: {Authorization: `Bearer ${token}`},
            data: {
                roomId: id
            }
        }).then(res => {
            dispatch(getRoomsAsync())
            dispatch(toggleLoading())
        }).catch(err => {
            console.log(err)
            dispatch(toggleLoading())
            alert('Already Bought')
        })
    }
}