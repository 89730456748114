import React, { useState } from 'react'
import {
    useHistory
} from 'react-router-dom'

import {
    IconButton,
    Menu,
    MenuItem
} from '@material-ui/core'

import {
    AccountCircle
} from '@material-ui/icons'

const UserIcon = props => {

    const [anchorEl, setAnchorEl] = useState(null);
    let username = localStorage.getItem('cphf-username')

    const history = useHistory()

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const route = path => {
        handleClose()
        history.push(path)
    }

    const logout = () => {
        localStorage.removeItem('cphf-token')
        localStorage.removeItem('cphf-username')
        localStorage.removeItem('cphf-type')
        window.location.reload()
    }

    return (
        <div>
            <IconButton color="inherit" onClick={handleClick}>
                <AccountCircle />
            </IconButton>

            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={() => {route(`/${username}`)}} >Profile</MenuItem>
                <MenuItem onClick={() => {logout()}}>Logout</MenuItem>
                {(localStorage.getItem('cphf-type') === 'admin' ? <MenuItem onClick={() => {route('/admin/dashboard')}} >Admin Panel</MenuItem> : null)}
            </Menu>
        </div>
    )
}

export default UserIcon