import React from 'react'

import {
    Grid,
    makeStyles,
    Paper,
    FormControl,
    TextField,
    Select,
    MenuItem,
    Button
} from '@material-ui/core'

const useStyle = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2)
    }
}))

const RoomPass = props => {

    const classes = useStyle()
    const rooms = [{name: 'room 1', id: 1}, {name: 'room 2', id: 2},]

    return (
        <div>

            <Paper className={classes.paper} variant="outlined">

                <Grid container spacing={2}>

                    <Grid item xs={6}>

                        <FormControl fullWidth>

                            <Select
                            variant="outlined"
                            value={1}
                            >
                                {
                                    rooms.map(el => <MenuItem value={el.id}>{el.name}</MenuItem>)
                                }
                            </Select>

                        </FormControl>

                    </Grid>

                    <Grid item xs={6}>

                        <FormControl fullWidth>
                            <TextField 
                            label="password" 
                            variant="outlined"
                            />
                        </FormControl>

                    </Grid>
                    <Grid item xs={12}>

                        <FormControl fullWidth>
                            <Button>
                                Send 
                            </Button>
                        </FormControl>

                    </Grid>

                </Grid>

            </Paper>
            
        </div>
    )
}

export default RoomPass