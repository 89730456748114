/**
 * Action constants for redux 
 */

export const GET_USER_STAT = "GET_USER_STAT"
export const GET_ROOMS = "GET_ROOMS"
export const BUY_PASS = "BUY_PASS"


// ui Constants
export const TOGGLE_LOADING = "TOGGLE_LOADING"