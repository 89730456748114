import React from 'react'

const Leaderboard = props => {
    
    return (
        <div>
            <h1>Leaderboard Page</h1>
        </div>
    )
}

export default Leaderboard