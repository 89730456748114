import React,{useState} from 'react'

import {
    makeStyles,
    Grid,
    Divider,
    Typography
} from '@material-ui/core'

// importing components 
import Room from '../../components/admin/create.room'
import Stat from '../../components/admin/update.stat'
import RoomPass from '../../components/admin/send.room.pass'

const useStyle = makeStyles(theme => ({
    root: {
        margin: theme.spacing(6,2)
    },
    leftPannel: {
        padding: theme.spacing(1,2),
        cursor: 'pointer',
        marginBottom: theme.spacing(1)
    },
    selected: {
        color: 'red'
    }
}))

const Dashboard = props => {
    const classes = useStyle()
    const [sideComponent,sideComponentSet] = useState(0)
    const sideComp = [{name: 'Create Room', id: 0}, {name: 'Update User Stat', id: 1},{name: 'Send room password', id: 2}]

    const component = num => {
        switch(num){
            case 0:
                return <Room />
            case 1:
                return <Stat />
            case 2:
                return <RoomPass />
            default:
                return <></>
        }
    }

    const componentHandler = num => {
        sideComponentSet(num)
    }

    return (
        <div className={classes.root}>
                <Grid container>
                    <Grid item sm={3}>

                        {sideComp.map(el => <div id={el.id} className={classes.leftPannel}>
                            <Typography
                            onClick={() => {componentHandler(el.id)}}
                            className={(sideComponent === el.id)? classes.selected: null}
                            >
                                {el.name}
                            </Typography>       
                            <Divider />             
                        </div>)}

                    </Grid>
                    <Grid item sm={9}>
                        {component(sideComponent)}
                    </Grid>
                </Grid>
        </div>
    )
}

export default Dashboard