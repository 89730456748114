import React from 'react'

import {
    makeStyles,
    Container,
    Typography,
    Grid,
    Divider
} from '@material-ui/core'

const useStyle = makeStyles(theme => ({
    root: {
        margin: theme.spacing(6, 2)
    },
    mainTitle: {
        margin: theme.spacing(4, 0)
    },
    divider: {
        margin: theme.spacing(2,4)
    }
}))

const Index = props => {
    const classes = useStyle()

    return (
        <div className={classes.root}>
            <Container>
                <div className={classes.mainTitle}>
                    <Typography variant="h3" align="center" style={{fontFamily: 'Staatliches'}}>
                        CU Pubg Hall Of Fame
                </Typography>
                </div>

                <div>
                    <Typography align="right">
                        This is a platform for all the pubg lovers of our college. <br/>
                        Built for and by <strong>students</strong>.
                    </Typography>
                </div>
                <Divider variant="middle" className={classes.divider} />

                <div className={classes.mainTitle}>
                    <Typography variant="h4" >
                        Main Idea !! 
                    </Typography>

                    <Typography>
                        Well a lot of people in our college plays pubg, and some are really good. <br/>
                        So we thought, why not create a platform for all pubg enthusiast. 
                         
                    </Typography>
                </div>
            </Container>
        </div>
    )
}

export default Index