import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css'
import { ThemeProvider, CssBaseline } from '@material-ui/core'
import theme from './theme'
import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import reducer from './store/reducer'
import { Provider } from 'react-redux'

const store = createStore(reducer, applyMiddleware(thunk))

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </CssBaseline>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
);


