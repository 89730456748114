import React,{ useState } from 'react'
import { connect } from 'react-redux'
import {
    getRoomsAsync
} from '../store/actions/main'

import RoomHolder from '../components/holders/room'

const Rooms = props => {

    useState(() => {
        props.fetchRooms()
        console.log(props.rooms)
    },[])

    return (
        <div>
            {props.rooms.map(el => <RoomHolder 
            id={el._id} 
            title={el.title} 
            date={el.date}
            time={el.time}
            passesLeft={el.passesLeft}
            type={el.type}
            />)}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        rooms: state.main.rooms
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchRooms: () => { dispatch(getRoomsAsync()) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Rooms)