import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Axios from 'axios'
import { LOGIN_URL } from '../../constants/urls'

import {
    makeStyles,
    Container,
    Paper,
    FormControl,
    TextField,
    Typography,
    Button
} from '@material-ui/core'

const useStyle = makeStyles(theme => ({
    root: {
        margin: theme.spacing(6, 2),
        [theme.breakpoints.down('xs')]: {
            margin: theme.spacing(4, 1)
        }
    },
    paper: {
        padding: theme.spacing(6, 12),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(4, 2)
        }
    },
    input: {
        margin: theme.spacing(2, 0)
    }
}))

const Login = props => {
    const classes = useStyle()
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const history = useHistory()

    const loginHandler = () => {
        if (username === '' || password === '') {
            return;
        }

        Axios({
            method: 'POST',
            url: LOGIN_URL,
            data: {
                username: username,
                password: password
            }
        }).then(res => {
            localStorage.setItem('cphf-token', res.data.token)
            localStorage.setItem('cphf-type', res.data.type)
            localStorage.setItem('cphf-username', res.data.username)
            history.push('/')
        }).catch(err => {
            console.log('login error', err)
            alert('There is something wrong')
        })
    }

    return (
        <div className={classes.root}>

            <Container>
                <Paper variant="outlined" className={classes.paper}>
                    <FormControl fullWidth className={classes.input}>
                        <TextField
                            label="Username"
                            variant="outlined"
                            type="text"
                            value={username}
                            onChange={(event) => {
                                setUsername(event.target.value)
                            }}
                        />
                    </FormControl>
                    <FormControl fullWidth className={classes.input}>
                        <TextField
                            label="password"
                            variant="outlined"
                            type="password"
                            value={password}
                            onChange={(event) => {
                                setPassword(event.target.value)
                            }}
                        />
                    </FormControl>

                    <Typography>
                        Dont have a account? <Link to="/signup">Signup</Link>
                    </Typography>

                    <center>
                        <Button
                            size="large"
                            variant="contained"
                            color="primary"
                            onClick={loginHandler}
                        >
                            Login
                        </Button>
                    </center>
                </Paper>
            </Container>
        </div>
    )
}

export default Login