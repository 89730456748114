import { combineReducers } from 'redux'
// importing reducers
import user from './reducers/user'
import main from './reducers/main'
import ui from './reducers/ui'

const rootReducer = combineReducers({
    user,
    main,
    ui
})

export default rootReducer